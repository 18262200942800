import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import { SfFaceIdMIcon } from '@alfalab/icons-glyph/SfFaceIdMIcon';

import BackButton from '#/src/components/ui/back-button';
import ServerErrorsNotificationNew from '#/src/components/ui/server-errors-notification/server-errors-notification-new';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { getIsPincodeReady } from '#/src/lib/get-is-pincode-available';
import getPathnameEndpoint from '#/src/lib/get-pathname-endpoint';
import { getUserLocalInfo } from '#/src/lib/user-info';
import { Routes } from '#/src/models';
import { useRequestAkeyDeeplinkMutation } from '#/src/store/api/akey-api';
import { selectAkeyDeeplink, selectAkeyError, selectIsAKeyAvailable } from '#/src/store/redux/akey/selectors';
import { akeyDeeplinkSet, akeyErrorCleared } from '#/src/store/redux/akey/slice';
import { selectAlfaPayBackUrl, selectAlfaPaySubtitle, selectAlfaPayTitle } from '#/src/store/redux/alfa-pay/selectors';
import {
    getQueryRedirectParams,
    isGlobalPreloaderVisible as setIsGlobalPreloaderVisible,
    selectAKeyRequestCount,
    selectAKeyTimeout,
} from '#/src/store/redux/app/selectors';
import { akeyStatusSet, preloaderShown, queryParamCleared } from '#/src/store/redux/app/slice';
import { selectPincodeAttemptsLeft } from '#/src/store/pincode/selectors';

import { LogoAnimated } from '../logo-animated';

import './akey.css';

const cn = createCn('akey');

/**
 * Страница AKey.
 * Доступ по прямой ссылке запрещен к этой странице. Переход возможен только через редирект на фронте.
 */
const AKey: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const [requestAkeyDeeplink] = useRequestAkeyDeeplinkMutation();
    const attemptsLeft = useAppSelector(selectPincodeAttemptsLeft);
    const deeplink = useAppSelector(selectAkeyDeeplink);
    const error = useAppSelector(selectAkeyError);
    const isAKeyAvailable = useAppSelector(selectIsAKeyAvailable);
    const xUserInfo = useAppSelector(getUserLocalInfo);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const alfaPayMerchantTitle = useAppSelector(selectAlfaPayTitle);
    const alfaPayMerchantSubtitle = useAppSelector(selectAlfaPaySubtitle);
    const alfaPayMerchantBackUrl = useAppSelector(selectAlfaPayBackUrl);
    const isGlobalPreloaderVisible = useAppSelector(setIsGlobalPreloaderVisible);
    const isPincodeReady = useAppSelector(getIsPincodeReady);
    const akeyTimeout = useAppSelector(selectAKeyTimeout);
    const akeyRequestCount = useAppSelector(selectAKeyRequestCount);

    const currentRoute = getPathnameEndpoint(pathname);

    useEffect(() => {
        let requestInterval: ReturnType<typeof setTimeout>;

        if (currentRoute && currentRoute === Routes.AKEY) {
            if (!deeplink) {
                requestAkeyDeeplink();
            }

            requestInterval = setInterval(() => {
                requestAkeyDeeplink();
            }, akeyTimeout);

            setTimeout(() => {
                clearInterval(requestInterval);
            }, akeyTimeout * (akeyRequestCount - 1));
        }

        return () => {
            if (requestInterval) {
                clearInterval(requestInterval);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleButtonClick = (route: string, clearState?: boolean) => {
        trackUserEvent('AKey Page', 'Click', 'Go to login page', queryRedirectParams.client_id);

        if (clearState) {
            dispatch(preloaderShown());
            dispatch(akeyDeeplinkSet(''));
            dispatch(akeyStatusSet(true));
            dispatch(queryParamCleared('pc_session_id'));
        }

        navigate({
            pathname: route,
            search,
        });
    };

    const title = () => {
        if (alfaPayMerchantTitle) {
            return (
                <div className={cn('header')}>
                    <Typography.TitleResponsive
                        rowLimit={2}
                        className={cn('title')}
                        view='large'
                        font='styrene'
                        tag='h1'
                        dataTestId='merchant-title'
                    >
                        {alfaPayMerchantTitle}
                    </Typography.TitleResponsive>
                    <Typography.Text
                        rowLimit={2}
                        tag='div'
                        dataTestId='merchant-subtitle'
                        view='primary-large'
                    >
                        {alfaPayMerchantSubtitle || (
                            <React.Fragment>Для оплаты войдите в&nbsp;Альфа-Онлайн</React.Fragment>
                        )}
                    </Typography.Text>
                </div>
            );
        }

        if (xUserInfo.name) {
            return (
                <div className={cn('header')}>
                    <Typography.TitleResponsive view='large' font='styrene' tag='h1' dataTestId='title'>
                        Привет, {xUserInfo.name}!
                    </Typography.TitleResponsive>
                    <Typography.Text
                        view='primary-large'
                        color='secondary'
                        className={cn('text')}
                        tag='div'
                        dataTestId='subtitle'
                    >
                        Вам доступен быстрый вход
                        <br /> в&nbsp;Альфа-Онлайн.
                    </Typography.Text>
                </div>
            );
        }

        return (
            <div className={cn('header')}>
                <Typography.TitleResponsive view='large' font='styrene' tag='h1' dataTestId='title'>
                    Добро пожаловать
                    <br />
                    в&nbsp;Альфа-Онлайн
                </Typography.TitleResponsive>
                <Typography.Text
                    view='primary-large'
                    color='secondary'
                    className={cn('text')}
                    tag='div'
                    dataTestId='subtitle'
                >
                    Вам доступен быстрый вход.
                </Typography.Text>
            </div>
        );
    };

    if (isAKeyAvailable) {
        if (currentRoute && currentRoute === Routes.AKEY) {
            return (
                <div className={cn('container', { hidden: isGlobalPreloaderVisible })}>
                    {alfaPayMerchantBackUrl && (
                        <div className={cn('back-button')}>
                            <BackButton backUrl={alfaPayMerchantBackUrl} />
                        </div>
                    )}
                    <div className={cn('content-top')}>
                        <div className={cn('logo')} data-test-id='logo'>
                            <LogoAnimated isVisible={!isGlobalPreloaderVisible} />
                        </div>
                    </div>

                    <div className={cn('divider')} />

                    <div className={cn('content-bottom')}>
                        {title()}

                        {error && (
                            <ServerErrorsNotificationNew
                                errorMessage={error}
                                onClose={() => {
                                    dispatch(akeyErrorCleared());
                                }}
                            />
                        )}

                        <div className={cn('buttons')}>
                            {isAKeyAvailable && deeplink && (
                                <Button
                                    className={cn('button')}
                                    view='primary'
                                    block={true}
                                    target='_blank'
                                    href={deeplink}
                                    leftAddons={<SfFaceIdMIcon />}
                                    dataTestId="deeplink-button"
                                >
                                    Войти
                                </Button>
                            )}

                            {isPincodeReady && attemptsLeft ? (
                                <Button
                                    className={cn('button', { width: 'content' })}
                                    view='text'
                                    block={true}
                                    onClick={() => handleButtonClick(Routes.PINCODE)}
                                    dataTestId="pincode-button"
                                >
                                    Ввести секретный код
                                </Button>
                            ) : (
                                <Button
                                    className={cn('button', { width: 'content' })}
                                    view='text'
                                    block={true}
                                    onClick={() => handleButtonClick(Routes.LOGIN)}
                                    dataTestId="change-button"
                                >
                                    Войти другим способом
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <React.Fragment>
                {error && (
                    <ServerErrorsNotificationNew
                        errorMessage={error}
                        onClose={() => {
                            dispatch(akeyErrorCleared());
                        }}
                    />
                )}
                <Typography.Text
                    view='primary-medium'
                    color='secondary'
                    className={cn('separator')}
                    tag='div'
                >
                    или
                </Typography.Text>
                <Button
                    dataTestId="akey-button"
                    view='secondary'
                    block={true}
                    target='_blank'
                    onClick={() => handleButtonClick(Routes.AKEY, true)}
                    leftAddons={<SfFaceIdMIcon />}
                >
                    Face ID или Touch ID
                </Button>
            </React.Fragment>
        );
    }

    return null;
};

export default AKey;
