/**
 * Заголовки запросов специфичные для проекта, которые нужны для логирования и корреткной работы системы.
 */
export enum HEADERS {
    TRACE_ID = 'trace-id',
    USER_AGENT = 'user-agent',
    X_FORWARD_FOR = 'x-forwarded-for',
    X_FORWARD_PORT = 'x-forwarded-port',
    X_FORWARD_HOST = 'x-forwarded-host',
    X_FORWARDED_HOST = 'X-Forwarded-Host',
    X_REQUEST_ID = 'X-Request-ID',
    X_CSRF_TOKEN = 'X-CSRF-Token',
    USER_INFO = 'x-user-info',
    CUSTOMER_ID = 'x-user-id',
    COMPANY_ID = 'x-company-id',
}

export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
}

/**
 * Статусы, которые используется в кодовой базе.
 */
export enum HttpStatus {
    /**
     * Запрос успешно обработан.
     */
    OK = 200,
    /**
     * Не авторизован или протух токен.
     */
    Unauthorized = 401,
    /**
     * Если авторизован, однако не достаточно прав для доступа к ресурсу.
     */
    Forbidden = 403,
    /**
     * Ресурс не найден
     * Однако без уточнения ресурса не было в принципе или только сейчас.
     */
    NotFound = 404,
    /**
     * Внутренняя ошибка сервера. Сервере реализовался сценарий, который не был предусмотрен и/или обработан.
     */
    InternalServerError = 500,
}

export enum LogLevel {
    Trace = 'TRACE',
    Info = 'INFO',
    Warn = 'WARN',
    Error = 'ERROR',
}

export const CodeLevelMapping = {
    [HttpStatus.OK]: LogLevel.Info,
    [HttpStatus.Unauthorized]: LogLevel.Warn,
    [HttpStatus.Forbidden]: LogLevel.Warn,
    [HttpStatus.NotFound]: LogLevel.Warn,
    [HttpStatus.InternalServerError]: LogLevel.Error,
} as const;

export enum Endpoint {
  ALTERNATIVE_LOGIN_GET = '/api/getAlternativeLogin',
  ALTERNATIVE_LOGIN_DEFINE_IS_FREE = '/api/alternativeLogin/isFree',
  AM_PHONE_REDIRECT_UPDATE = '/api/am-phone-redirect/update',
  AM_PHONE_REDIRECT_DELETE = '/api/am-phone-redirect/delete',
  AM_PHONE_REDIRECT = '/api/am-phone-redirect',
  LIMITS_CLICK_GET = '/api/getLimitsClick',
  FAST_REGISTRATION = '/api/fastRegistration',
  FULL_NAME_REGISTRATION = '/api/fullNameRegistration',
  GET_PASSWORD_TYPE = '/api/getPasswordType',
  HEALTH_MONITOR = '/healthmonitor',
  LOG_ERROR = '/api/logError',
  OID_AKEY_AUTH = '/api/oid/AKeyAuthorization',
  OID_AKEY_DEEPLINK = '/api/oid/AKeyDeeplink',
  OID_FINISH_CUSTOMER_REGISTRATION = '/api/oid/finishCustomerRegistration',
  OID_INITIALIZE = '/api/oid/initialize',
  OID_PASSCODE = '/api/oid/passcodeAuthorization',
  TEMP_PASSWORD = '/api/tempPasswordAuthorization',
  OID_REFERENCE = '/api/oid/reference',
  OID_REGISTRATION_CUSTOMER = '/api/oid/registerCustomer',
  OID_REGISTRATION_JWT_AUTH = '/api/JWTAuthorization',
  OID_REGISTRATION_BY_PHONE = '/api/oid/phoneRegistrationCustomer',
  OID_REGISTRATION_UPDATE = '/api/update',
  OID_REGISTRATION_RETAIL_PASSWORD_UPDATE = '/api/retail-update-password-service/update',
  OID_AUTH = '/api/oid/authorize',
  OID_AUTH_GET_TEMP_PASSWORD_ALBO = '/api/getTempPasswordAlbo',
  QR_AUTH = '/api/oid/QRAuthorization',
  SCANNER_ACTIVATOR_ACTIVATE = '/api/scanner-activator/activate',
  PINCODE_ACTIVATE = '/api/pincodeActivate',
  PINCODE_AUTHORIZATION = '/api/pincodeAuthorization',
  PINCODE_FINISH_AUTHORIZATION = '/api/pincodeFinishAuthorization',
  WS = '/ws',
  WEB_AUTHN_AUTHORIZATION = '/api/webauthnAuthorization',
  WEB_AUTHN_CHALLANGE = '/api/webauthnChallenge',
  WEB_AUTHN_REG_CHALLENGE = '/api/webauthnRegChallenge',
  WEB_AUTHN_REGISTRATION = '/api/webauthnRegistration',
  CLIENT_LOGGING_ENDPOINT = '/api/logger/logClientMessage',
}

export const BANK_URL = 'https://alfabank.ru';

export const DEFAULT_PHONE_COUNTRY_CODE = '+7';

export const DEVICE_UUID_MAP = 'DeviceUuidStore';

export const SUB_ALFABANK_DOMAIN = '.alfabank.ru';

export const FHP_SCRIPT_ID = 'FHP_SCRIPT_ID';
