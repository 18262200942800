import errorDictionary from '../error-dictionary';
import { iosScanType } from '../models';

import { addSpacesToCardNumber } from './formatters';

/*
 * @description Бридж для общения фронта с нативом
 */
function removeBridgeFunctions(): void {
    delete window.mobileApp;
}

function callMobileScanner(): void {
    if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.openCardScannerScreen
    ) {
        // Новая логика работы со сканером карты на iOS 
        window.webkit.messageHandlers.openCardScannerScreen?.postMessage([]);
    } else if (
        // Устаревшая логика работы со сканером карты на iOS
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.scanCard
    ) {
        window.webkit.messageHandlers.scanCard.postMessage?.({ scanType: iosScanType.cardIO });
    } else if (window.Android && window.Android.openCardScannerScreen) {
        window.Android.openCardScannerScreen();
    }
}

export function errorHandlerBridge(status: number, id: string, text: string): void {
    if (window.webkit?.errorHandlers?.send) {
        window.webkit.errorHandlers.send({
            status,
            id,
            text,
        });
    } else if (window.Android?.handleError) {
        window.Android.handleError({
            status,
            id,
            text,
        });
    }
}

export function scanCard(): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
        if (typeof window !== 'undefined') {
            window.mobileApp = {
                getScannedCard(cardJson) {
                    if (cardJson) {
                        const card = addSpacesToCardNumber(JSON.parse(cardJson).cardNumber);

                        resolve(card);
                    } else {
                        reject(errorDictionary.SCAN_ERROR);
                    }
                    removeBridgeFunctions();
                },
            };
            callMobileScanner();
        } else {
            reject(errorDictionary.SCAN_ERROR);
        }
    });
}

export function enterDemoOnIOS(): void {
    if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.navigateToDemo
    ) {
        window.webkit.messageHandlers.navigateToDemo.postMessage([]);
    }
}

export function openSupportInfoScreen() {
    if (window.Android && window.Android.openCallInfoScreen) {
        window.Android.openCallInfoScreen();
    }
}

export function openCallScreenNative() {
    if (window.Android && window.Android.openCallScreen) {
        window.Android.openCallScreen();
    }
}
