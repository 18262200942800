import { createSlice } from '@reduxjs/toolkit';

import { AlfaPayInitialState } from '#/src/store/redux/alfa-pay/constants';

export const alfaPaySlice = createSlice({
    name: 'alfaPay',
    initialState: AlfaPayInitialState,
    reducers: {
        alfaPayParamSet(state, action) {
            return {...state, ...action.payload}
        },
    }
})

export const {
    alfaPayParamSet,
} = alfaPaySlice.actions;

export default alfaPaySlice.reducer
