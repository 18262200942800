import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import loadable from '@loadable/component';
import { createCn } from 'bem-react-classname';
import { conformToMask } from 'text-mask-core';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { MaskedInput } from '@alfalab/core-components/masked-input';
import { Skeleton } from '@alfalab/core-components/skeleton';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import AKey from '#/src/components/akey';
import { Scanner } from '#/src/components/card-scanner';
import { ScannerButton } from '#/src/components/card-scanner/scanner-button';
import CoinGame from '#/src/components/ui/game-coin';
import ServerErrorNotificationsNew from '#/src/components/ui/server-errors-notification/server-errors-notification-new';
import ErrorDictionary from '#/src/error-dictionary';
import { useAppDispatch, useAppSelector, useIsomorphicLayoutEffect } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import {
    validateAccountNumber,
    validateCardNumber,
} from '#/src/lib/client-validation/registration';
import { ACCOUNT_INPUT_MAX_LENGTH, CARD_INPUT_MAX_LENGTH } from '#/src/lib/form-controls-const';
import getPathnameEndpoint from '#/src/lib/get-pathname-endpoint';
import { ButtonNames, ClientIds, FormStatus, RegistrationType, Routes } from '#/src/models';
import { useRequestRegistrationMutation } from '#/src/store/api/registration-api';
import { selectIsAKeyAvailable } from '#/src/store/redux/akey/selectors';
import {
    getQueryRedirectParams,
    selectIsAccountAuthEnabled,
    selectIsCardScannerEnabled,
    selectIsMobile,
} from '#/src/store/redux/app/selectors';
import { selectSkeletonVisible } from '#/src/store/redux/passcode';
import {
    getRegistrationAccount,
    getRegistrationCard,
    getRegistrationFormError,
    getRegistrationFormStatus,
    getRegistrationServerErrors,
    getRegistrationType,
    selectIsSubmitButtonDisabled,
    selectRegistrationErrorUpdate,
} from '#/src/store/redux/registration/selectors';
import {
    errorUpdateMessageDeleted,
    registrationErrorUpdated,
    registrationFormUpdated,
    registrationServerErrorsCleared,
    registrationSubmit,
    registrationTypeChanged,
    registrationTypeUpdated,
    serverErrorNotificationClosed,
} from '#/src/store/redux/registration/slice';
import { getWebAuthnFlag } from '#/src/store/redux/webauthn/selectors';
import { ApplicationState } from '#/src/store/types';

import { ModalsContextProvider } from '../../alternative-login/modals/modals-context-provider';
import BackButton from '../../ui/back-button';
import WebAuthn from '../../webauthn';

import './card-account-browser.css';

const AlternativeLoginBrowser = loadable(
    () => import('../../alternative-login/alternative-login-browser'),
);

const cn = createCn('card-account-browser');

// eslint-disable-next-line complexity
const CardAccountBrowser = () => {
    const dispatch = useAppDispatch();
    const [requestRegistration] = useRequestRegistrationMutation();
    const type = useAppSelector(getRegistrationType);
    const serverErrors = useAppSelector(getRegistrationServerErrors);
    const isCardScannerEnabled = useAppSelector(selectIsCardScannerEnabled);
    const env = useAppSelector((state: ApplicationState) => state.Settings.env);
    const formStatus = useAppSelector(getRegistrationFormStatus);
    const card = useAppSelector(getRegistrationCard);
    const account = useAppSelector(getRegistrationAccount);
    const formError = useAppSelector(getRegistrationFormError);
    const isSubmitButtonDisabled = useAppSelector(selectIsSubmitButtonDisabled);
    const { pathname } = useLocation();
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const errorUpdate = useAppSelector(selectRegistrationErrorUpdate);
    const isMobile = useAppSelector(selectIsMobile);
    const isAccountAuthEnabled = useAppSelector(selectIsAccountAuthEnabled);
    const webAuthnFlag = getWebAuthnFlag();
    const skeletonVisible = useAppSelector(selectSkeletonVisible);
    const isAKeyAvailable = useAppSelector(selectIsAKeyAvailable);
    const [isWebAuthnAvailable, setIsWebAuthnAvailable] = useState(false);

    const [firstKeyPush, setFirstKeyPush] = useState(false);
    const [scannerOpened, setScannerOpened] = useState(false);
    const cardInputRef = useRef<HTMLInputElement | null>(null);
    const endpoint = getPathnameEndpoint(pathname);
    const hideBackButton = queryRedirectParams.client_id === ClientIds.lkGreenrobleWeb;
    const showScannerButton = isCardScannerEnabled && isMobile && !card && !account;
    const nbsp = String.fromCharCode(160);
    const labelText = isAccountAuthEnabled ? 'Номер карты или счёта' : 'Номер карты';

    useIsomorphicLayoutEffect(() => {
        setIsWebAuthnAvailable(isMobile && !isAKeyAvailable && webAuthnFlag);
    }, []);

    const openScanner = useCallback(() => {
        setScannerOpened(true);
    }, []);
    const closeScanner = () => {
        setScannerOpened(false);
    };
    const onManualEnter = () => {
        closeScanner();
        Promise.resolve().then(() => {
            cardInputRef.current?.focus();
        });
    };

    const maskFn = useCallback((newValue: string) => {
        // prettier-ignore
        const cardMask = [
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/,
        ];
        // prettier-ignore
        const accountNumberMask = [
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/, ' ',
            /\d/, /\d/, /\d/, /\d/,
        ];

        // добавлен isAccountAuthEnabled, тк у maskInput нет ограничивающих пропсов
        return cardMask.length <= newValue.length && isAccountAuthEnabled
            ? accountNumberMask
            : cardMask;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(registrationTypeUpdated(type));
        trackUserEvent('Auth Page', 'Impression', 'Viewing Page', queryRedirectParams.client_id);
        if (errorUpdate) {
            dispatch(
                registrationErrorUpdated({
                    card: ErrorDictionary.GO_THROUGH_AGAIN,
                }),
            );
            dispatch(errorUpdateMessageDeleted());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formStatus === FormStatus.ValidationSuccess) {
            requestRegistration();
        }
    }, [formStatus, requestRegistration]);

    useEffect(() => {
        if (formError.account) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                `Account error: ${formError.account}`,
            );
        }
        if (formError.card) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                `Card error: ${formError.card}`,
            );
        }
    }, [formError, queryRedirectParams, endpoint]);

    useEffect(() => {
        if (serverErrors.length) {
            serverErrors.forEach((error) => {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Card Account Send',
                    queryRedirectParams.client_id,
                    `Server error: ${error.message}`,
                );
            });
        }
    }, [serverErrors, queryRedirectParams, endpoint]);

    useEffect(() => {
        const isCard = type === RegistrationType.Card;
        const clientNumber = isCard ? card : account;
        const trackMessage = isCard ? 'Card number filled' : 'Account filled';
        const maxClientNumberLength = isCard ? CARD_INPUT_MAX_LENGTH : ACCOUNT_INPUT_MAX_LENGTH;
        const validation = isCard ? validateCardNumber : validateAccountNumber;
        const validationError: Record<string, string | null> = {};

        if (clientNumber.length === maxClientNumberLength) {
            const validationResult: string | null = validation(clientNumber);

            if (validationResult === null) {
                trackUserEvent(
                    'Auth Page',
                    'Field Change',
                    'Fill Card Account',
                    queryRedirectParams.client_id,
                    trackMessage,
                );
            } else {
                validationError[isCard ? 'card' : 'account'] = validationResult;
                dispatch(registrationErrorUpdated(validationError));
            }
        }

        return () => {
            if (serverErrors.length) {
                dispatch(registrationServerErrorsCleared());
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, card, type]);

    useEffect(() => {
        if (queryRedirectParams.account_number) {
            const conformedPhoneNumber = conformToMask(
                queryRedirectParams.account_number,
                maskFn(queryRedirectParams.account_number),
                { guide: false },
            );
            const value = conformedPhoneNumber.conformedValue;

            dispatch(registrationTypeChanged(RegistrationType.Account));
            dispatch(
                registrationFormUpdated({
                    card: '',
                    account: value,
                }),
            );
            dispatch(
                registrationSubmit({
                    type: RegistrationType.Account,
                    account: value,
                    card: '',
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryRedirectParams]);

    const handleOnClick = (e: React.FormEvent | undefined) => {
        e?.preventDefault();
        if (!isSubmitButtonDisabled) {
            trackUserEvent(
                'Auth Page',
                'Click',
                'Card Account Send',
                queryRedirectParams.client_id,
                'Send',
            );
            dispatch(
                registrationSubmit({
                    type,
                    account,
                    card,
                }),
            );
        }
    };

    const handleClear = () => {
        dispatch(
            registrationFormUpdated({
                card: '',
                account: '',
            }),
        );
        dispatch(registrationTypeChanged(RegistrationType.Card));
        if (serverErrors.length) {
            dispatch(registrationServerErrorsCleared());
        }
        if (formError.card || formError.account) {
            dispatch(registrationErrorUpdated({ card: '', account: '' }));
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement> | null,
        { value }: { value: string },
    ) => {
        if (serverErrors.length) {
            dispatch(registrationServerErrorsCleared());
        }
        const trimmedValue = value.trim();

        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Enter Card Account',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }

        if (trimmedValue.length <= CARD_INPUT_MAX_LENGTH && card !== trimmedValue) {
            if (formError.card || formError.account) {
                dispatch(registrationErrorUpdated({ card: '', account: '' }));
            }
            dispatch(
                registrationFormUpdated({
                    card: trimmedValue,
                    account: '',
                }),
            );
            if (type !== RegistrationType.Card) {
                trackUserEvent(
                    'Auth Page',
                    'Switch',
                    'Authentication Type',
                    queryRedirectParams.client_id,
                    'From Account To Card',
                );
                dispatch(registrationTypeChanged(RegistrationType.Card));
            }
        } else if (
            isAccountAuthEnabled &&
            trimmedValue.length <= ACCOUNT_INPUT_MAX_LENGTH &&
            account !== trimmedValue
        ) {
            if (formError.card || formError.account) {
                dispatch(registrationErrorUpdated({ card: '', account: '' }));
            }
            dispatch(
                registrationFormUpdated({
                    card: '',
                    account: trimmedValue,
                }),
            );
            if (type !== RegistrationType.Account) {
                trackUserEvent(
                    'Auth Page',
                    'Switch',
                    'Authentication Type',
                    queryRedirectParams.client_id,
                    'From Card To Account',
                );
                dispatch(registrationTypeChanged(RegistrationType.Account));
            }
        }
    };

    const setCardNumber = (number: string) => {
        setTimeout(() => {
            cardInputRef.current?.focus();
            handleInputChange(null, { value: number });
        });
    };

    const renderHeader = () => (
        <Fragment>
            {!hideBackButton && (
                <div className={cn('back-button')}>
                    <BackButton />
                </div>
            )}
            <Skeleton
                visible={skeletonVisible}
                className={skeletonVisible ? cn('skeleton-title') : cn('skeleton')}
            >
                <Typography.Title className={cn('title')} view='small' font='styrene' tag='div'>
                    Ещё нужен номер карты{isAccountAuthEnabled ? ` или${nbsp}счёта` : ''}
                </Typography.Title>
            </Skeleton>
            <Gap size='2xl' direction='vertical' />
        </Fragment>
    );

    return (
        <div className={cn('container')}>
            {renderHeader()}
            <Form onSubmit={handleOnClick} noValidate={true} className={cn()}>
                <ServerErrorNotificationsNew
                    errorMessage={serverErrors[0]?.message}
                    onClose={() => {
                        dispatch(serverErrorNotificationClosed(0));
                    }}
                />
                <Skeleton visible={skeletonVisible}>
                    <MaskedInput
                        ref={cardInputRef}
                        className={cn('input')}
                        label={labelText}
                        clear={true}
                        size='xl'
                        block={true}
                        inputMode='numeric'
                        mask={maskFn}
                        onChange={handleInputChange}
                        value={type === RegistrationType.Card ? card : account}
                        rightAddons={
                            showScannerButton && <ScannerButton openScanner={openScanner} />
                        }
                        onClear={handleClear}
                        error={formError.card}
                    />
                </Skeleton>
                <Gap size='xl' direction='vertical' />
                <Skeleton
                    visible={skeletonVisible}
                    className={skeletonVisible ? cn('skeleton-button') : cn()}
                >
                    <Button
                        className={cn('button')}
                        view='primary'
                        block={true}
                        disabled={isSubmitButtonDisabled}
                        onClick={handleOnClick}
                        loading={formStatus === FormStatus.SubmitProcess}
                    >
                        {ButtonNames.continue}
                    </Button>
                </Skeleton>
                <Skeleton
                    visible={skeletonVisible}
                    className={skeletonVisible ? cn('skeleton-number') : cn()}
                >
                    <ModalsContextProvider>
                        <AlternativeLoginBrowser />
                    </ModalsContextProvider>
                </Skeleton>
                {scannerOpened && (
                    <Scanner
                        env={env}
                        close={closeScanner}
                        manualEnter={onManualEnter}
                        setCardNumber={setCardNumber}
                    />
                )}
                {isAKeyAvailable && isMobile && endpoint !== Routes.RESTORE && <AKey />}
                {isWebAuthnAvailable && isMobile && endpoint !== Routes.RESTORE && <WebAuthn />}
                {endpoint !== Routes.RESTORE && <CoinGame />}
            </Form>
        </div>
    );
};

export default CardAccountBrowser;
