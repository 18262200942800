import { combineReducers } from 'redux';
import akeyAuthorizationSlice from '#/src/store/redux/akey/slice';
import alfaPaySlice from '#/src/store/redux/alfa-pay/slice';
import appSlice from '#/src/store/redux/app/slice';
import authorizationSlice from '#/src/store/redux/authorization/slice';
import fingerprintSlice from '#/src/store/redux/fingerprint/slice';
import passcodeAuthorizationSlice from '#/src/store/redux/passcode/slice';
import pincodeSlice from '#/src/store/pincode/slice';
import qrAuthorizationSlice from '#/src/store/redux/qr-authorization/slice';
import registrationSlice from '#/src/store/redux/registration/slice';
import settingsSlice from '#/src/store/redux/setting/slice';
import smsVerificationSlice from '#/src/store/redux/sms-verification/slice';
import tempPasswordAuthorizationSlice from '#/src/store/redux/temp-password/slice';
import webAuthnAuthorizationSlice from '#/src/store/redux/webauthn/slice';
import { akeyApi } from '#/src/store/api/akey-api';
import { authorizationApi } from '#/src/store/api/authorization-api';
import { jwtApi } from '#/src/store/api/jwt-api';
import { passcodeApi } from '#/src/store/api/passcode-api';
import { pincodeApi } from '#/src/store/pincode/api';
import { qrAuthorizationApi } from '#/src/store/api/qr-authorization-api';
import { registrationApi } from '#/src/store/api/registration-api';
import { smsVerificationApi } from '#/src/store/api/sms-verification-api';
import { tempPasswordApi } from '#/src/store/api/temp-password-api';
import { webAuthnApi } from '#/src/store/api/webauthn-api';

export const rootReducer = combineReducers({
    AKeyAuthorization: akeyAuthorizationSlice,
    AlfaPay: alfaPaySlice,
    App: appSlice,
    Authorization: authorizationSlice,
    Fingerprint: fingerprintSlice,
    Pincode: pincodeSlice,
    QRAuthorization: qrAuthorizationSlice,
    WebAuthnAuthorization: webAuthnAuthorizationSlice,
    Registration: registrationSlice,
    SmsVerification: smsVerificationSlice,
    TempPasswordAuthorization: tempPasswordAuthorizationSlice,
    PasscodeAuthorization: passcodeAuthorizationSlice,
    Settings: settingsSlice,
    [akeyApi.reducerPath]: akeyApi.reducer,
    [authorizationApi.reducerPath]: authorizationApi.reducer,
    [jwtApi.reducerPath]: jwtApi.reducer,
    [passcodeApi.reducerPath]: passcodeApi.reducer,
    [pincodeApi.reducerPath]: pincodeApi.reducer,
    [qrAuthorizationApi.reducerPath]: qrAuthorizationApi.reducer,
    [registrationApi.reducerPath]: registrationApi.reducer,
    [smsVerificationApi.reducerPath]: smsVerificationApi.reducer,
    [tempPasswordApi.reducerPath]: tempPasswordApi.reducer,
    [webAuthnApi.reducerPath]: webAuthnApi.reducer,
});
