import { createSelector } from 'reselect';

import getCookie from '#/src/lib/get-cookie';
import { setCookie } from '#/src/lib/update-cookie';
import { CookiesName } from '#/src/models';
import { ApplicationState } from '#/src/store/types';

export interface AlfaPayProps {
    title: string;
    subtitle?: string;
    authorizationUrl?: string;
    backUrl: string;
}

/** Функция получения состояния alfa-pay. */
export const getAlfaPayState = (state: ApplicationState) => state.AlfaPay;

export const selectAlfaPayBackUrl = createSelector([getAlfaPayState], (alfaPay) => alfaPay.backUrl);
export const selectAlfaPayTitle = createSelector([getAlfaPayState], (alfaPay) => alfaPay.title);
export const selectAlfaPaySubtitle = createSelector(
    [getAlfaPayState],
    (alfaPay) => alfaPay.subtitle,
);
export const selectAlfaPayAuthorizationUrl = createSelector(
    [getAlfaPayState],
    (alfaPay) => alfaPay.authorizationUrl,
);

export const getAlfaPayCookies = (isMobile: boolean): Partial<AlfaPayProps> => {
    const merchantTitle = getCookie(CookiesName.alfaPayMerchantTitle);
    const merchantSubtitle = getCookie(CookiesName.alfaPayMerchantSubtitle);
    const merchantAuthorizationUrl = getCookie(CookiesName.alfapayAuthorizationUrl);
    const merchantBackUrl = getCookie(CookiesName.alfaPayMerchantBackUrl);

    if (merchantTitle && merchantBackUrl) {
        setCookie(CookiesName.alfaPayMerchantTitle, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });
        setCookie(CookiesName.alfaPayMerchantSubtitle, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });
        setCookie(CookiesName.alfapayAuthorizationUrl, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });
        setCookie(CookiesName.alfaPayMerchantBackUrl, '1', {
            domain: '.alfabank.ru',
            path: '/',
            maxAge: '-1',
        });

        return {
            title: merchantTitle,
            subtitle: isMobile ? '' : merchantSubtitle,
            authorizationUrl: merchantAuthorizationUrl,
            backUrl: merchantBackUrl,
        };
    }

    return {};
};
