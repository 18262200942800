import React, { FC, useEffect } from 'react';
import loadable from '@loadable/component';
import { createCn } from 'bem-react-classname';

import Page from 'arui-private/core/page';

import PlateNotification from '#/src/components/app/app-browser/header/plate-notification/plate-notification';
import { AppLoader } from '#/src/components/ui/loader';
import { useAppDispatch, useAppSelector, useAuthFactorCheck } from '#/src/hooks';
import { useSetupEventBus } from '#/src/hooks/use-setup-event-bus';
import { getAlfaPayCookies } from '#/src/store/redux/alfa-pay/selectors';
import { alfaPayParamSet } from '#/src/store/redux/alfa-pay/slice';
import { selectIsMobile } from '#/src/store/redux/app/selectors';
import { ApplicationState } from '#/src/store/types';

import Header from './header/header';

import './app-browser.css';

const VoipPanel = loadable(() => import('../../voip'));

const cn = createCn('custom-page');

interface Props {
    showHint: boolean;
    children?: React.ReactNode;
}

const AppBrowser: FC<Props> = ({ showHint, children }) => {
    const dispatch = useAppDispatch();
    const isMobile = useAppSelector(selectIsMobile);
    const isVoipEnabled = useAppSelector((state: ApplicationState) => state.App.isVoipEnabled);

    useAuthFactorCheck();
    useSetupEventBus();

    useEffect(() => {
        const { title, subtitle, authorizationUrl, backUrl } = getAlfaPayCookies(isMobile);

        if (title && backUrl) {
            dispatch(
                alfaPayParamSet({
                    title,
                    subtitle,
                    authorizationUrl,
                    backUrl,
                }),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AppLoader>
            <Page className={cn()} header={<Header showHint={showHint} data-testid='page' />}>
                <PlateNotification />
                {isVoipEnabled && <VoipPanel isMobile={isMobile} />}
                <div className={cn('content')}>{children}</div>
            </Page>
        </AppLoader>
    );
};

export default AppBrowser;
