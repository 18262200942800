import React from 'react';
import { createCn } from 'bem-react-classname';

import { Skeleton } from '@alfalab/core-components/skeleton';
import { Typography } from '@alfalab/core-components/typography';

import './assistant-dialog.css';

const cn = createCn('assistant-dialog');

interface Props {
    text: string;
    hideIcon?: boolean;
    skeletonVisible?: boolean;
}
// TODO убрать SkeletonComponent после раскатки на пользователей
const SkeletonComponent = () => (
    <div className={cn('main')}>
        <div className={cn('container')}>
            <Skeleton visible={true} className={cn('skeleton')} />
        </div>
        <div className={cn('container')}>
            <Skeleton visible={true} className={cn('skeleton')} />
        </div>
    </div>
);

const assistantDialog: React.FC<Props> = ({ text, hideIcon, skeletonVisible }) => (
    <div>
        {!hideIcon && <div className={skeletonVisible ? cn('none') : cn('avatar')} />}
        {skeletonVisible ? (
            <SkeletonComponent />
        ) : (
            <Typography.TitleMobile
                view='small'
                font='system'
                color='primary'
                className={cn('text')}
                tag='h1'
            >
                {text}
            </Typography.TitleMobile>
        )}
    </div>
);

export default assistantDialog;
