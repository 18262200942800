import { history } from '#/src/history';
import getRouteFromRedirect from '#/src/lib/get-route-from-redirect';
import getQueryParams from '#/src/lib/get-query-params';

export const redirectTo = (route: string) => {
    const path = getRouteFromRedirect(route);
    const getSearchParams = `?${new URLSearchParams(getQueryParams(route))}`;
    const search = getSearchParams === '?' ? history.location.search : getSearchParams;
    history.push(path + search);
};
