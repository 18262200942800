import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { createApi } from '@reduxjs/toolkit/query/react';

import { declination } from '#/src/lib/declination';
import { rsaEncrypt } from '#/src/lib/rsa-encrypt';
import { AcrValues, FormStatus } from '#/src/models';
import { customFetchBaseQuery } from '#/src/store/base-query';
import { selectClientId, selectScope } from '#/src/store/redux/app/selectors';
import {
    selectClearPhoneNumber,
    selectPasscode,
    selectPasscodeNonce,
    selectPasscodeServerPublicKey,
    selectRequestDataInit,
} from '#/src/store/redux/passcode';
import {
    activeTypePasscodeSet,
    isPasscodeAuthSet,
    passcodeRequest,
    passcodeRequestResolved,
    passcodeUpdated,
} from '#/src/store/redux/passcode/slice';
import { ApplicationState } from '#/src/store/types';
import { PasscodeCredentials } from '#/src/types/interfaces';
import { Endpoint, HttpMethod } from '#/src/utils';

import { PasscodeInitializeResponse } from '../../redux/passcode/types';

const encryptPasscode = async (state: ApplicationState) => {
    const code = selectPasscode(state);
    const serverPublicKey = selectPasscodeServerPublicKey(state);
    const nonce = selectPasscodeNonce(state);

    return rsaEncrypt(code, serverPublicKey, nonce);
};

const getAuthorizationCredentials = async (
    state: ApplicationState,
): Promise<PasscodeCredentials> => {
    const phoneNumber = selectClearPhoneNumber(state);
    const clientId = selectClientId(state);
    const passcodeEnc = await encryptPasscode(state);
    const scope = selectScope(state);

    return {
        phone: phoneNumber,
        passcodeEnc,
        clientId,
        scope,
    };
};

export const passcodeApi = createApi({
    reducerPath: 'passcodeApi',
    baseQuery: customFetchBaseQuery(),
    endpoints: (build) => ({
        initializePasscode: build.mutation<PasscodeInitializeResponse, void>({
            queryFn: async (_payload, queryApi, _extraOptions, fetchWithBQ) => {
                const state = queryApi.getState() as ApplicationState;
                const { dispatch } = queryApi;

                const requestDataPasscode = selectRequestDataInit(state, AcrValues.passcode);

                dispatch(passcodeUpdated({ formStatus: FormStatus.SubmitProcess }));

                const result = await fetchWithBQ({
                    url: Endpoint.OID_INITIALIZE,
                    method: HttpMethod.POST,
                    body: requestDataPasscode,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;
                    const { id } = errorResult.errors[0];

                    dispatch(
                        passcodeUpdated({
                            serverErrorId: id,
                            formStatus: FormStatus.SubmitError,
                            isPasscodeAuth: false,
                        }),
                    );

                    return { error: result.error as FetchBaseQueryError };
                }

                const data = result.data as PasscodeInitializeResponse;

                dispatch(passcodeRequestResolved(data));
                dispatch(isPasscodeAuthSet(true));

                dispatch(
                    passcodeUpdated({
                        formStatus: FormStatus.SubmitSuccess,
                    }),
                );

                if ('passcodeTypes' in data) {
                    dispatch(activeTypePasscodeSet(data.passcodeTypes[0]));
                }

                return { data };
            },
        }),
        requestPasscodeAuthorization: build.mutation<any, void>({
            queryFn: async (_payload, queryApi, _extraOptions, fetchWithBQ) => {
                const state = queryApi.getState() as ApplicationState;
                const { dispatch } = queryApi;

                dispatch(passcodeRequest());

                const requestData = await getAuthorizationCredentials(state);

                const result = await fetchWithBQ({
                    url: Endpoint.OID_PASSCODE,
                    method: HttpMethod.POST,
                    body: requestData,
                });

                if (result.error) {
                    const errorResult = result.error.data as any;
                    const { data, id } = errorResult.errors[0];
                    const { attemptsLeft, nonce } = data || {};

                    dispatch(
                        passcodeUpdated({ formStatus: FormStatus.SubmitError, serverErrorId: id }),
                    );

                    if (attemptsLeft) {
                        const error = declination(attemptsLeft, [
                            'Осталась 1 попытка',
                            'Код не совпадает',
                        ]);

                        dispatch(
                            passcodeUpdated({ attemptsLeft, nonce, error, showToastError: true }),
                        );
                    } else {
                        dispatch(passcodeUpdated({ attemptsLeft: 0 }));
                    }

                    return { error: result.error as FetchBaseQueryError };
                }
                dispatch(passcodeUpdated({ formStatus: FormStatus.SubmitSuccess }));
                
                return { data: result.data };
            },
        }),
    }),
});
export const { useInitializePasscodeMutation, useRequestPasscodeAuthorizationMutation } =
    passcodeApi;
