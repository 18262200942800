import React, { ChangeEvent, FC, FormEvent, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Input } from '@alfalab/core-components/input';
import { PasswordInput } from '@alfalab/core-components/password-input';
import { Typography } from '@alfalab/core-components/typography';
import Form from 'arui-feather/form';

import AKey from '#/src/components/akey';
import QRLogin from '#/src/components/qr-login';
import BackButton from '#/src/components/ui/back-button';
import CoinGame from '#/src/components/ui/game-coin';
import Help from '#/src/components/ui/help';
import ServerErrorNotificationsNew from '#/src/components/ui/server-errors-notification/server-errors-notification-new';
import WebAuthn from '#/src/components/webauthn';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { useIsomorphicLayoutEffect } from '#/src/hooks/use-isomorphical-layout-effect';
import { trackUserEvent } from '#/src/lib/analitycs';
import { validateLogin, validatePassword } from '#/src/lib/client-validation/authorization';
import { LOGIN_INPUT_MAX_LENGTH, PASSWORD_INPUT_MAX_LENGTH } from '#/src/lib/form-controls-const';
import getPathnameEndpoint from '#/src/lib/get-pathname-endpoint';
import { getUserLocalInfo } from '#/src/lib/user-info';
import { ButtonNames, FormStatus, Routes, ValidationType } from '#/src/models';
import { useRequestOIDAuthByLoginMutation } from '#/src/store/api/authorization-api';
import { selectIsAKeyAvailable } from '#/src/store/redux/akey/selectors';
import { selectAlfaPayBackUrl, selectAlfaPaySubtitle, selectAlfaPayTitle } from '#/src/store/redux/alfa-pay/selectors';
import {
    getQueryRedirectParams,
    isGlobalPreloaderVisible as setIsGlobalPreloaderVisible,
    selectFirstVisitedLogin,
    selectIsMobile,
} from '#/src/store/redux/app/selectors';
import { keyboardLayoutChecked, pageFirstVisited, passwordSet } from '#/src/store/redux/app/slice';
import {
    getAuthorizationFormError,
    getAuthorizationFormStatus,
    getAuthorizationLogin,
    getAuthorizationMaskedLogin,
    getAuthorizationPassword,
    getAuthorizationServerErrors,
    selectIsSubmitButtonDisabled,
} from '#/src/store/redux/authorization/selectors';
import {
    authorizationErrorUpdated,
    authorizationFormReset,
    authorizationFormUpdated,
    authorizationPasswordReset,
    authorizationServerErrorReset,
    authorizationValidated,
    authServerErrorNotificationClosed,
} from '#/src/store/redux/authorization/slice';
import { selectRegistrationErrorUpdate } from '#/src/store/redux/registration/selectors';
import { errorUpdateMessageDeleted } from '#/src/store/redux/registration/slice';
import { getWebAuthnFlag } from '#/src/store/redux/webauthn/selectors';

import './login-browser.css';

const cn = createCn('login');

const LoginBrowser: FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [requestOIDAuthByLogin] = useRequestOIDAuthByLoginMutation();
    const navigate = useNavigate();
    const [xUserInfo, setXUserInfo] = useState<ReturnType<typeof getUserLocalInfo> | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const alfaPayMerchantBackUrl = useAppSelector(selectAlfaPayBackUrl);
    const alfaPayMerchantTitle = useAppSelector(selectAlfaPayTitle);
    const alfaPayMerchantSubtitle = useAppSelector(selectAlfaPaySubtitle);
    const login = useAppSelector(getAuthorizationLogin);
    const maskedLogin = useAppSelector(getAuthorizationMaskedLogin);
    const formStatus = useAppSelector(getAuthorizationFormStatus);
    const error = useAppSelector(getAuthorizationFormError);
    const isAKeyAvailable = useAppSelector(selectIsAKeyAvailable);
    const serverErrors = useAppSelector(getAuthorizationServerErrors);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);
    const firstVisitedStatus = useAppSelector(selectFirstVisitedLogin);
    const isSubmitButtonDisabled = useAppSelector(selectIsSubmitButtonDisabled);
    const password = useAppSelector(getAuthorizationPassword);
    const errorUpdate = useAppSelector(selectRegistrationErrorUpdate);
    const isMobile = useAppSelector(selectIsMobile);
    const webAuthnFlag = getWebAuthnFlag();
    const isGlobalPreloaderVisible = useAppSelector(setIsGlobalPreloaderVisible);

    const [firstKeyPush, setFirstKeyPush] = useState(false);
    const currentRoute = getPathnameEndpoint(location.pathname);
    const [isWebAuthnAvailable, setIsWebAuthnAvailable] = useState(false);

    const clearErrors = () => {
        if (error.login) {
            dispatch(
                authorizationErrorUpdated({
                    login: '',
                }),
            );
        }
        if (serverErrors.length) {
            dispatch(authorizationServerErrorReset());
        }
        if (error.password) {
            dispatch(
                authorizationErrorUpdated({
                    password: '',
                }),
            );
        }
    };

    useIsomorphicLayoutEffect(() => {
        setIsWebAuthnAvailable(isMobile && !isAKeyAvailable && webAuthnFlag);
        setXUserInfo(getUserLocalInfo());
    }, []);

    useEffect( () => {
        if (!isGlobalPreloaderVisible) {
            inputRef?.current?.focus()
        }
    }, [isGlobalPreloaderVisible] );

    useEffect(() => {
        if (!firstVisitedStatus) {
            dispatch(pageFirstVisited('login'));
            trackUserEvent(
                'Auth Page',
                'Impression',
                'Viewing Page',
                queryRedirectParams.client_id,
            );
        }

        return () => {
            dispatch(authorizationFormReset());
            dispatch(authorizationPasswordReset());
            dispatch(passwordSet(password));
            if (errorUpdate) {
                dispatch(errorUpdateMessageDeleted());
            }
            clearErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const authorizationByLogin = () => {
        requestOIDAuthByLogin({
            username: login,
            password,
            queryRedirectParams,
            currentRoute,
        });
    };

    const handleValidateLogin = (value: string) => {
        const validationStatus = validateLogin(value, false);

        if (validationStatus !== null) {
            dispatch(
                authorizationValidated({
                    validationStatus,
                    validationType: ValidationType.Login,
                }),
            );
        }
    };

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (maskedLogin && (e.key === 'Backspace' || e.key === 'Execute')) {
            dispatch(
                authorizationFormUpdated({
                    maskedLogin: '',
                    login: '',
                }),
            );
        }
    };

    const handleOnChangeLogin = (value: string) => {
        let val = value;

        if (maskedLogin && maskedLogin !== val) {
            if (val.length === maskedLogin.length + 1) {
                // typing
                dispatch(
                    authorizationFormUpdated({
                        maskedLogin: '',
                    }),
                );
                val = val.slice(-1);
            } else if (val.length > maskedLogin.length + 5) {
                // pasting
                dispatch(
                    authorizationFormUpdated({
                        maskedLogin: '',
                    }),
                );
                val = val.slice(maskedLogin.length);
            } else {
                dispatch(
                    authorizationFormUpdated({
                        maskedLogin: '',
                    }),
                );
            }
        }

        dispatch(
            authorizationFormUpdated({
                login: val,
            }),
        );

        if (serverErrors.length) {
            dispatch(authorizationServerErrorReset());
        }

        if (error.login) {
            dispatch(
                authorizationErrorUpdated({
                    login: '',
                }),
            );
        }

        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Fill Username',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }

        handleValidateLogin(val);

        dispatch(keyboardLayoutChecked({ character: val.slice(-1), input: 'login' }));
    };

    const handleOnClear = () => {
        dispatch(
            authorizationFormUpdated({
                login: '',
                maskedLogin: '',
            }),
        );
    };

    const handleOnClick = (event?: FormEvent) => {
        event?.preventDefault();
        if (!isSubmitButtonDisabled) {
            const usernameStatus = validateLogin(login, false);
            const passwordStatus = validatePassword(password, false);

            if (usernameStatus !== null) {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Username Send',
                    queryRedirectParams.client_id,
                    'Error Username Validation',
                );
            }

            if (passwordStatus !== null) {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Password Send',
                    queryRedirectParams.client_id,
                    'Error Password Validation',
                );
            }

            if (usernameStatus === null && passwordStatus === null) {
                trackUserEvent(
                    'Auth Page',
                    'Click',
                    'Username/Password Send',
                    queryRedirectParams.client_id,
                    'Send',
                );
                authorizationByLogin();
            }
        }
    };

    const handleOnRestore = () => {
        navigate({
            pathname: Routes.PHONE_AUTH,
            search: location.search,
        });
    };

    const handleValidatePassword = (value: string) => {
        const validationStatus = validatePassword(value, false);

        if (validationStatus !== null) {
            dispatch(
                authorizationValidated({
                    validationStatus,
                    validationType: ValidationType.Password,
                }),
            );
        }
    };

    const handleChangePassword = (value: string) => {
        if (serverErrors.length) {
            dispatch(authorizationServerErrorReset());
        }
        if (error.password) {
            dispatch(
                authorizationErrorUpdated({
                    password: '',
                }),
            );
        }

        if (!firstKeyPush) {
            trackUserEvent(
                'Auth Page',
                'Field Change',
                'Fill Password',
                queryRedirectParams.client_id,
            );
            setFirstKeyPush(true);
        }

        handleValidatePassword(value);

        dispatch(
            authorizationFormUpdated({
                password: value,
            }),
        );
        dispatch(keyboardLayoutChecked({ character: value.slice(-1), input: 'password' }));
    };

    const title = () => {
        if (alfaPayMerchantTitle) {
            return (
                <div className={cn('header')}>
                    <Typography.TitleResponsive
                        rowLimit={2}
                        dataTestId='merchant-title'
                        className={cn('custom-header')}
                        view='medium'
                        font='styrene'
                        tag='h1'
                        defaultMargins={true}
                    >
                        {alfaPayMerchantTitle}
                    </Typography.TitleResponsive>
                    <Typography.Text
                        dataTestId='merchant-subtitle'
                        rowLimit={2}
                        tag='div'
                        view='primary-large'
                    >
                        {alfaPayMerchantSubtitle || (
                            <React.Fragment>Для оплаты войдите в&nbsp;Альфа-Онлайн</React.Fragment>
                        )}
                    </Typography.Text>
                </div>
            );
        }

        if (xUserInfo?.name) {
            return (
                <Typography.Title
                    dataTestId='login-title'
                    className={cn('header')}
                    view='medium'
                    font='styrene'
                    tag='h1'
                >
                    Привет, {xUserInfo.name}!
                    <br />
                    Войдите в&nbsp;Альфа-Онлайн
                </Typography.Title>
            );
        }

        return (
            <Typography.Title
                dataTestId='login-title'
                className={cn('header')}
                view='medium'
                font='styrene'
                tag='h1'
            >
                Привет!
                <br />
                Войдите в&nbsp;Альфа-Онлайн
            </Typography.Title>
        );
    };

    return (
        <div className={cn()}>
            {alfaPayMerchantBackUrl && (
                <div className={cn('back-button')}>
                    <BackButton backUrl={alfaPayMerchantBackUrl} />
                </div>
            )}
            {title()}
            <Form className={cn('form')} noValidate={true} onSubmit={handleOnClick}>
                <ServerErrorNotificationsNew
                    errorMessage={serverErrors[0]?.message}
                    onClose={() => {
                        dispatch(authServerErrorNotificationClosed());
                    }}
                />

                <Input
                    className={cn('input')}
                    value={maskedLogin || login}
                    ref={inputRef}
                    maxLength={LOGIN_INPUT_MAX_LENGTH}
                    label='Логин'
                    clear={true}
                    size='xl'
                    block={true}
                    error={error.login}
                    onChange={(e, { value }) => handleOnChangeLogin(value)}
                    onClear={handleOnClear}
                    onKeyDown={handleKeyDown}
                />
                <PasswordInput
                    className={cn('input')}
                    value={password}
                    onChange={(e: ChangeEvent<HTMLInputElement>, { value }: { value: string }) =>
                        handleChangePassword(value)
                    }
                    maxLength={PASSWORD_INPUT_MAX_LENGTH}
                    label='Пароль'
                    size='xl'
                    error={error.password}
                    block={true}
                    autoCapitalize='none'
                />
                <Button
                    className={cn('button')}
                    type='submit'
                    view='primary'
                    block={true}
                    disabled={isSubmitButtonDisabled}
                    onClick={handleOnClick}
                    loading={formStatus === FormStatus.SubmitProcess}
                >
                    {ButtonNames.letsGo}
                </Button>

                {isAKeyAvailable && <AKey />}

                {isWebAuthnAvailable && <WebAuthn />}

                <Button className={cn('restore')} view='text' onClick={handleOnRestore}>
                    {ButtonNames.iDontHaveLoginOrPassword}
                </Button>

                <CoinGame />

                {!isMobile && <QRLogin className={cn('qr')} />}

                <Help className={cn('help')} />
            </Form>
        </div>
    );
};

export default LoginBrowser;
