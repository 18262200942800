import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { KeyPadButton, PassCodeV1 } from '@alfalab/core-components/pass-code-v1';
import { Typography } from '@alfalab/core-components/typography';
import { SfFaceIdXxlIcon } from '@alfalab/icons-glyph/SfFaceIdXxlIcon';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { clientInfoLog } from '#/src/lib/client-logger';
import { validatePincode } from '#/src/lib/client-validation/authorization';
import { expiresDate } from '#/src/lib/expires-date';
import { setCookie } from '#/src/lib/update-cookie';
import { getUserLocalInfo } from '#/src/lib/user-info';
import { ButtonNames, CookiesName, Routes } from '#/src/models';
import { useRequestWebAuthnChallengeMutation } from '#/src/store/api/webauthn-api';
import { usePincodeAuthorizationMutation } from '#/src/store/pincode/api';
import {
    selectIsPincodeButtonDisabled,
    selectPincode,
    selectPincodeAttemptsLeft,
    selectPincodeError,
} from '#/src/store/pincode/selectors';
import { pincodeErrorSet, pincodeSet, pincodeVisitedSet } from '#/src/store/pincode/slice';
import { selectAkeyDeeplink, selectIsAKeyAvailable } from '#/src/store/redux/akey/selectors';
import { selectAlfaPaySubtitle, selectAlfaPayTitle } from '#/src/store/redux/alfa-pay/selectors';
import { selectIsMobile } from '#/src/store/redux/app/selectors';
import { getWebAuthnFlag } from '#/src/store/redux/webauthn/selectors';
import { SUB_ALFABANK_DOMAIN } from '#/src/utils';

import { ErrorScreen } from './components/error-screen';
import { Modal } from './components/modal';

import './pincode-mobile.css';

export const cn = createCn('pincode-mobile');

type RightAddonsPropTypes = {
    isWebAuthnAvailable: boolean;
    deeplink: string;
    requestWebAuthnChallenge: () => void;
};

const RightAddons: FC<RightAddonsPropTypes> = ({ deeplink, isWebAuthnAvailable, requestWebAuthnChallenge }) => {
    if (deeplink || isWebAuthnAvailable) {
        const handleClickAddon = () => {
            if (deeplink) {
                window.location.replace(deeplink)
            } else {
                requestWebAuthnChallenge();
            }
        };

        return (
            <KeyPadButton view='transparent' onClick={handleClickAddon}>
                <SfFaceIdXxlIcon />
            </KeyPadButton>
        );
    }

    return null;
};

const PINCODE_MAX_LENGTH = 8;

const getHeader = (
    alfaPayMerchantTitle: string,
    alfaPayMerchantSubtitle: string,
    xUserInfoName: string,
) => {
    let header = (
        <Typography.TitleResponsive view='medium' font='styrene' tag='h1'>
            Добро пожаловать
            <br />
            в&nbsp;Альфа-Онлайн
        </Typography.TitleResponsive>
    );
    let message = (
        <Typography.Text
            view='primary-medium'
            color='secondary'
            className={cn('subtitle')}
            tag='div'
        >
            Вам доступна быстрая авторизация.
        </Typography.Text>
    );

    if (alfaPayMerchantTitle) {
        header = (
            <Typography.TitleResponsive
                rowLimit={2}
                className={cn('header')}
                view='medium'
                font='styrene'
                tag='h1'
                dataTestId='merchant-title'
            >
                {alfaPayMerchantTitle}
            </Typography.TitleResponsive>
        );

        message = (
            <Typography.Text
                className={cn('subtitle')}
                rowLimit={2}
                tag='div'
                dataTestId='merchant-subtitle'
                view='primary-large'
            >
                {alfaPayMerchantSubtitle || (
                    <React.Fragment>Для оплаты войдите в&nbsp;Альфа-Онлайн</React.Fragment>
                )}
            </Typography.Text>
        );
    }

    if (xUserInfoName) {
        header = (
            <Typography.TitleResponsive view='medium' font='styrene' tag='h1'>
                Привет, {xUserInfoName}!
            </Typography.TitleResponsive>
        );

        message = (
            <Typography.Text
                view='primary-medium'
                color='secondary'
                className={cn('subtitle')}
                tag='div'
            >
                Введите код для входа в&nbsp;Альфа-Онлайн
            </Typography.Text>
        );
    }

    return {
        header,
        message,
    };
};

const PincodeMobile: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [requestWebAuthnChallenge] = useRequestWebAuthnChallengeMutation();

    const { search } = useLocation();
    const [requestPincodeAuthorization] = usePincodeAuthorizationMutation();

    const attempts = useAppSelector(selectPincodeAttemptsLeft);
    const alfaPayMerchantTitle = useAppSelector(selectAlfaPayTitle);
    const alfaPayMerchantSubtitle = useAppSelector(selectAlfaPaySubtitle);
    const code = useAppSelector(selectPincode);
    const deeplink = useAppSelector(selectAkeyDeeplink);
    const error = useAppSelector(selectPincodeError);
    const xUserInfo = useAppSelector(getUserLocalInfo);
    const isMobile = useAppSelector(selectIsMobile);
    const webAuthnFlag = getWebAuthnFlag();
    const isAKeyAvailable = useAppSelector(selectIsAKeyAvailable);
    const buttonDisabled = useAppSelector(selectIsPincodeButtonDisabled);

    const [open, setOpen] = useState(false);
    const [isWebAuthnAvailable, setIsWebAuthnAvailable] = useState(false);

    useEffect( () => {
        setIsWebAuthnAvailable(isMobile && !isAKeyAvailable && webAuthnFlag);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );

    const { header, message } = getHeader(
        alfaPayMerchantTitle,
        alfaPayMerchantSubtitle,
        xUserInfo.name,
    );

    const LeftAddon = (
        <KeyPadButton
            view='transparent'
            onClick={() => {
                setOpen(true);
            }}
        >
            Забыли код?
        </KeyPadButton>
    );

    const onChange = (codeValue: string) => {
        dispatch(pincodeSet(codeValue));

        if (error) {
            dispatch(pincodeErrorSet(''));
        }
    }

    const handleSubmit = () => {
        const validation = validatePincode(code);

        if (validation === null) {
            requestPincodeAuthorization();
        } else {
            dispatch(pincodeErrorSet(validation));
            dispatch(pincodeSet(''));
        }
    }

    if (!attempts) {
        return (
            <ErrorScreen
                onChange={() => {
                    navigate({
                        pathname: Routes.LOGIN,
                        search,
                    });
                }}
            />
        );
    }

    return (
        <div className={cn('container')}>
            {header}

            <PassCodeV1
                className={cn('keyboard')}
                key='passcode'
                value={code}
                message={message}
                error={error}
                maxCodeLength={PINCODE_MAX_LENGTH}
                leftAddons={LeftAddon}
                rightAddons={RightAddons({
                    deeplink,
                    isWebAuthnAvailable,
                    requestWebAuthnChallenge,
                })}
                onChange={onChange}
            />

            <div className={cn('button-wrapper')}>
                <Button
                    className={cn('button')}
                    block={true}
                    view='primary'
                    disabled={buttonDisabled}
                    onClick={handleSubmit}
                >
                    {ButtonNames.forward}
                </Button>
            </div>

            <Modal
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                onChange={() => {
                    setCookie(CookiesName.forgottenPasscode, 'true', {
                        domain: SUB_ALFABANK_DOMAIN,
                        path: '/',
                        expires: expiresDate(7),
                    });
                    clientInfoLog('forgottenPasscode set');
                    navigate({
                        pathname: Routes.LOGIN,
                        search,
                    });
                    dispatch(pincodeVisitedSet(true));
                }}
            />
        </div>
    );
};

export default PincodeMobile;
