import { BrowserSecretPublicKey } from '#/src/models';
import { AppState } from '#/src/store/redux/app/types';

export const AKEY_TIMEOUT = process.env.AKEY_TIMEOUT ? +process.env.AKEY_TIMEOUT : 60_000;
export const AKEY_REQUEST_COUNT = process.env.AKEY_REQUEST_COUNT
    ? +process.env.AKEY_REQUEST_COUNT
    : 3;
const MOBILE_APP_DOWNLOAD_LINK = process.env.MOBILE_APP_DOWNLOAD_LINK || '';
const FINGER_PRINT_ENABLED = process.env.FINGER_PRINT_ENABLED === '1';
const PINCODE_ENABLED = process.env.PINCODE_ENABLED === '1';
const BROWSER_SECRET_PUBLIC_KEY = process.env.BROWSER_SECRET_PUBLIC_KEY || BrowserSecretPublicKey;
const BROWSER_SECRET_ENABLED = process.env.BROWSER_SECRET_ENABLED === '1';
const ACCOUNT_NUMBER_AUTH_ENABLED = process.env.ACCOUNT_NUMBER_AUTH_ENABLED === '1';
const WEBAUTHN_REG_ENABLED = process.env.WEBAUTHN_REG_ENABLED === '1';
const CARD_SCANNER_ENABLED = process.env.CARD_SCANNER_ENABLED === '1';
const GAME_URL = process.env.GAME_URL ? process.env.GAME_URL : '';
const GAME_ENABLED = process.env.GAME_ENABLED === '1';
const PASSWORD_RECOVER_LINK = process.env.PASSWORD_RECOVER_LINK || '';
const INVESTMENTS_URL = process.env.INVESTMENTS_URL ? process.env.INVESTMENTS_URL : '';
const REDIRECT_ENABLED = process.env.REDIRECT_ENABLED === '1';
const BECOME_CLIENT_URL = process.env.BECOME_CLIENT_URL ? process.env.BECOME_CLIENT_URL : '';
const GOS_USLUGI_AUTHORIZATION_URL = process.env.GOS_USLUGI_AUTHORIZATION_URL || '';
const LOGIN_RECOVER_LINK = process.env.LOGIN_RECOVER_LINK ? process.env.LOGIN_RECOVER_LINK : '';
const METRICS_ENDPOINT_URL = process.env.METRICS_ENDPOINT_URL || '';
const USE_METRICS = process.env.USE_METRICS === '1';
const IS_CHAT_ENABLED = process.env.IS_CHAT_ENABLED === '1';
const IS_VOIP_ENABLED = process.env.IS_VOIP_ENABLED === '1';
const IS_VOIP_COLLAPSED_ENABLED = process.env.IS_VOIP_COLLAPSED_ENABLED === '1';
// TODO Удалить после раскатки мидла CS2222-1181
const UPDATE_BANNER_ENABLED = process.env.UPDATE_BANNER_ENABLED === '1';

export const AppInitialState: AppState = {
    notificationsData: null,
    isOIDAuthMode: process.env.OID_MODE,
    warnIfRussianLayout: {
        password: false,
        login: false,
    },
    firstVisitedMetrics: {
        login: false,
        password: false,
    },
    isMobile: false,
    IEVersion: '',
    capsLockPushed: false,
    queryRedirectParams: {
        acr_values: '',
        client_id: '',
        scope: '',
    },
    multiFactorResponseParams: {},
    clientId: '',
    oldBrowser: false,
    changedFlow: false,
    become_client_url: BECOME_CLIENT_URL,
    gos_uslugi_authorization_url: GOS_USLUGI_AUTHORIZATION_URL,
    mobileOnboardingDesignOsVersions: {
        iOS: process.env.MOBILE_ONBOARDING_DESIGN_IOS_VERSION || '1000',
        Android: process.env.MOBILE_ONBOARDING_DESIGN_ANDROID_VERSION || '1000',
    },
    investments_url: INVESTMENTS_URL,
    isRedirectEnabled: REDIRECT_ENABLED,
    password: '',
    isAKeyEnabled: true,
    isAKeyVisited: false,
    AKeyTimeout: AKEY_TIMEOUT,
    AKeyRequestCount: AKEY_REQUEST_COUNT,
    preloaderShown: true,
    multiFactorResponseRedirectUrl: '',
    gameUrl: GAME_URL,
    isGameEnabled: GAME_ENABLED,
    passwordRecoverLink: PASSWORD_RECOVER_LINK,
    loginRecoverLink: LOGIN_RECOVER_LINK,
    isWebAuthnEnabled: true,
    webAuthnRegEnabled: WEBAUTHN_REG_ENABLED,
    isCardScannerEnabled: CARD_SCANNER_ENABLED,
    iosAppIdRedirect: '',
    iosAppVersionRedirect: '',
    mobileAppDownloadLink: MOBILE_APP_DOWNLOAD_LINK,
    isFingerPrintEnabled: FINGER_PRINT_ENABLED,
    isPincodeEnabled: PINCODE_ENABLED,
    browserSecretPublicKey: BROWSER_SECRET_PUBLIC_KEY,
    isBrowserSecretEnabled: BROWSER_SECRET_ENABLED,
    isAccountAuthEnabled: ACCOUNT_NUMBER_AUTH_ENABLED,
    isMetricsEnabled: USE_METRICS,
    metricsEndpointUrl: METRICS_ENDPOINT_URL,
    metricsAppId: 'passport_metrics',
    metricsUrl: 'https://metrics.alfabank.ru/metrica/sp.js',
    isChatEnabled: IS_CHAT_ENABLED,
    isVoipEnabled: IS_VOIP_ENABLED,
    isVoipCollapsedEnabled: IS_VOIP_COLLAPSED_ENABLED,
    isUpdateBannerEnabled: UPDATE_BANNER_ENABLED,
};
