import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';
import { Typography } from '@alfalab/core-components/typography';
import { SfFaceIdMIcon } from '@alfalab/icons-glyph/SfFaceIdMIcon';

import BackButton from '#/src/components/ui/back-button';
import ServerErrorsNotificationNew from '#/src/components/ui/server-errors-notification/server-errors-notification-new';
import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { checkBrowserSecret } from '#/src/lib/check-browser-secret';
import { getIsPincodeReady } from '#/src/lib/get-is-pincode-available';
import getPathnameEndpoint from '#/src/lib/get-pathname-endpoint';
import { getUserLocalInfo } from '#/src/lib/user-info';
import { Routes } from '#/src/models';
import { replaceLastPath } from '#/src/routes/utils';
import { useRequestWebAuthnChallengeMutation } from '#/src/store/api/webauthn-api';
import { selectAlfaPayBackUrl, selectAlfaPaySubtitle, selectAlfaPayTitle } from '#/src/store/redux/alfa-pay/selectors';
import {
    isClickDesign,
    isGlobalPreloaderVisible,
    selectBrowserSecretPublicKey,
    selectIsBrowserSecretEnabled,
    selectIsWebAuthnEnabled,
} from '#/src/store/redux/app/selectors';
import { isAkeyVisitedSet, preloaderHidden, preloaderShown, webAuthnStatusSet } from '#/src/store/redux/app/slice';
import { selectPincodeAttemptsLeft } from '#/src/store/pincode/selectors';
import { pincodeUpdated } from '#/src/store/pincode/slice';
import { getWebAuthnFlag, selectWebAuthnError } from '#/src/store/redux/webauthn/selectors';
import { webAuthnErrorCleared } from '#/src/store/redux/webauthn/slice';

import { LogoAnimated } from '../logo-animated';

import './webauthn.css';

const cn = createCn('webauthn');

const WebAuthn: React.FC = () => {
    const dispatch = useAppDispatch();
    const webAuthnFlag = getWebAuthnFlag();
    const xUserInfo = getUserLocalInfo();
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const [requestWebAuthnChallenge] = useRequestWebAuthnChallengeMutation();
    const alfaPayMerchantBackUrl = useAppSelector(selectAlfaPayBackUrl);
    const alfaPayMerchantTitle = useAppSelector(selectAlfaPayTitle);
    const alfaPayMerchantSubtitle = useAppSelector(selectAlfaPaySubtitle);
    const error = useAppSelector(selectWebAuthnError);
    const isWebAuthnEnabled = useAppSelector(selectIsWebAuthnEnabled);
    const isPincodeReady = useAppSelector(getIsPincodeReady);
    const attemptsLeft = useAppSelector(selectPincodeAttemptsLeft);
    const globalPreloaderVisible = useAppSelector(isGlobalPreloaderVisible);

    const currentRoute = getPathnameEndpoint(pathname);
    const shouldChangePage =
        isWebAuthnEnabled &&
        (currentRoute === Routes.LOGIN || currentRoute === Routes.CARD_ACCOUNT);

    const isWebAuthn = getWebAuthnFlag();
    const isBrowserSecretEnabled = useAppSelector(selectIsBrowserSecretEnabled);
    const browserSecretPublicKey = useAppSelector(selectBrowserSecretPublicKey);
    const clickDesign = useAppSelector(isClickDesign);

    useEffect(() => {
        if (currentRoute && currentRoute === Routes.WEBAUTHN) {
            dispatch(preloaderShown());

            if (!isWebAuthn && !isWebAuthnEnabled) {
                navigate(`${replaceLastPath(pathname, Routes.LOGIN)}${search}`);

                return;
            }

            if (isBrowserSecretEnabled && clickDesign && browserSecretPublicKey) {
                const newBrowserSecretData = checkBrowserSecret(browserSecretPublicKey);

                dispatch(isAkeyVisitedSet(true));

                if (newBrowserSecretData) {
                    dispatch(pincodeUpdated(newBrowserSecretData));
                }
            }

            dispatch(preloaderHidden());
        }

        if (!webAuthnFlag && !shouldChangePage) {
            navigate({
                pathname: Routes.LOGIN,
                search,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRoute]);

    useEffect(() => {
        if (shouldChangePage) {
            navigate({
                pathname: Routes.WEBAUTHN,
                search,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldChangePage]);

    const handleButtonClick = (route: string, clearState?: boolean) => {
        if (clearState) {
            dispatch(webAuthnStatusSet(false));
        } else {
            dispatch(preloaderShown());
        }

        navigate({
            pathname: route,
            search,
        });
    };

    const requestAuthorization = () => {
        requestWebAuthnChallenge();
    };

    const title = () => {
        if (alfaPayMerchantTitle) {
            return (
                <React.Fragment>
                    <Typography.TitleResponsive
                        rowLimit={2}
                        className={cn('header')}
                        view='large'
                        font='styrene'
                        tag='h1'
                        dataTestId='merchant-title'
                    >
                        {alfaPayMerchantTitle}
                    </Typography.TitleResponsive>
                    <Typography.Text
                        rowLimit={2}
                        tag='div'
                        dataTestId='merchant-subtitle'
                        view='primary-large'
                    >
                        {alfaPayMerchantSubtitle || (
                            <React.Fragment>Для оплаты войдите в&nbsp;Альфа-Онлайн</React.Fragment>
                        )}
                    </Typography.Text>
                </React.Fragment>
            );
        }

        if (xUserInfo.name) {
            return (
                <React.Fragment>
                    <Typography.TitleResponsive view='large' font='styrene' tag='h1'>
                        Привет, {xUserInfo.name}!
                    </Typography.TitleResponsive>
                    <Typography.Text
                        view='primary-large'
                        color='secondary'
                        className={cn('text')}
                        tag='div'
                    >
                        Вам доступен быстрый вход
                        <br /> в&nbsp;Альфа-Онлайн.
                    </Typography.Text>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Typography.TitleResponsive view='large' font='styrene' tag='h1'>
                    Добро пожаловать
                    <br />
                    в&nbsp;Альфа-Онлайн
                </Typography.TitleResponsive>
                <Typography.Text
                    view='primary-large'
                    color='secondary'
                    className={cn('text')}
                    tag='div'
                >
                    Вам доступен быстрый вход.
                </Typography.Text>
            </React.Fragment>
        );
    };

    if (currentRoute && currentRoute === Routes.WEBAUTHN) {
        return (
            <div className={cn('container')}>
                {alfaPayMerchantBackUrl && (
                    <div className={cn('back-button')}>
                        <BackButton backUrl={alfaPayMerchantBackUrl} />
                    </div>
                )}
                <div className={cn('content-top')}>
                    <div className={cn('logo')}>
                        <LogoAnimated isVisible={!globalPreloaderVisible} />
                    </div>

                    {error && (
                        <ServerErrorsNotificationNew
                            errorMessage={error}
                            onClose={() => {
                                dispatch(webAuthnErrorCleared());
                            }}
                        />
                    )}
                </div>

                <div className={cn('divider')} />

                <div className={cn('content-bottom')}>
                    {title()}

                    <div className={cn('buttons')}>
                        <Button
                            className={cn('button')}
                            view='primary'
                            block={true}
                            target='_blank'
                            onClick={requestAuthorization}
                            leftAddons={<SfFaceIdMIcon />}
                        >
                            Войти
                        </Button>

                        {isPincodeReady && attemptsLeft ? (
                            <Button
                                className={cn('button', { width: 'content' })}
                                view='text'
                                block={true}
                                onClick={() => handleButtonClick(Routes.PINCODE, true)}
                            >
                                Ввести секретный код
                            </Button>
                        ) : (
                            <Button
                                className={cn('button', { width: 'content' })}
                                view='text'
                                block={true}
                                onClick={() => handleButtonClick(Routes.LOGIN, true)}
                            >
                                Войти другим способом
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Typography.Text
                view='primary-medium'
                color='secondary'
                className={cn('separator')}
                tag='div'
            >
                или
            </Typography.Text>
            <Button
                view='secondary'
                block={true}
                target='_blank'
                onClick={() => handleButtonClick(Routes.WEBAUTHN, false)}
                leftAddons={<SfFaceIdMIcon />}
            >
                Face ID или Touch ID
            </Button>
        </React.Fragment>
    );
};

export default WebAuthn;
