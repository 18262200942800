import React, { FC, useState } from 'react';
import { createCn } from 'bem-react-classname';

import Heading from 'arui-feather/heading';
import IconCall from 'arui-feather/icon/action/call';
import IconButton from 'arui-feather/icon-button';
import Link from 'arui-feather/link';
import Popup from 'arui-feather/popup';
import PopupHeader from 'arui-feather/popup-header';

import { useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { FORM_LINK_SIZE_M } from '#/src/lib/form-controls-const';
import {
    HelpLink, Routes,
} from '#/src/models';
import { getQueryRedirectParams, selectIsCorporateClientId } from '#/src/store/redux/app/selectors';

import addAttributes from '../../../utils/decorators/add-attributes';

import './phones.css';

const AttributedIconCall = addAttributes(IconButton, [
    { name: 'aria-label', value: 'Контакты' },
]);

interface Props {
    currentRoute: string;
}

const cn = createCn('phones');

const Phones: FC<Props> = ({ currentRoute }) => {
    const isCorporateClientId = useAppSelector(selectIsCorporateClientId);
    const queryRedirectParams = useAppSelector(getQueryRedirectParams);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleIconClick = () => {
        setIsModalVisible(state => !state);
    };

    const handleRussianPhoneClick = () => {
        trackUserEvent(
            'Auth Page',
            'Click',
            'Russian Phone',
            queryRedirectParams.client_id,
        );
    };

    const handleMoscowPhoneClick = () => {
        trackUserEvent(
            'Auth Page',
            'Click',
            'Moscow Phone',
            queryRedirectParams.client_id,
        );
    };

    const handleQuestionClick = () => {
        trackUserEvent(
            'Auth Page',
            'Click',
            'Question',
            queryRedirectParams.client_id,
        );
    };

    const getRussiaPhone = () =>
        isCorporateClientId ? '8 (800) 100-77-33' : '8 (800) 200-00-00';

    const getMoscowPhone = () =>
        isCorporateClientId ? '+7 (495) 755-58-58' : '+7 (495) 78-888-78';

    const handleModalClose = () => {
        setIsModalVisible(false);
    };


    const renderModal = () => (
        <Popup
            className={cn('modal')}
            target='screen'
            header={
                <PopupHeader
                    className={cn('modal-closer')}
                    size='s'
                    onCloserClick={handleModalClose}
                />
            }
            visible={isModalVisible}
            padded={true}
        >
            <div className={cn('modal-content')}>
                <Heading size='s'>Получение информации и проведение операций 24/7</Heading>
                <div className={cn('item')}>
                    <div className={cn('num')}>
                        <Link
                            size='m'
                            url={`tel:${getRussiaPhone()}`}
                            onClick={handleRussianPhoneClick}
                        >
                            {getRussiaPhone()}
                        </Link>
                    </div>
                    <div
                        className={
                            currentRoute === Routes.FORGOT_LOGIN ||
                                currentRoute === Routes.FORGOT_PASSWORD
                                ? cn('desc_white')
                                : cn('desc')
                        }
                    >
                        для звонков по России
                    </div>
                </div>
                <div className={cn('item')}>
                    <div className={cn('num')}>
                        <Link
                            size='m'
                            url={`tel:${getMoscowPhone()}`}
                            onClick={handleMoscowPhoneClick}
                        >
                            {getMoscowPhone()}
                        </Link>
                    </div>
                    <div
                        className={
                            currentRoute === Routes.FORGOT_LOGIN ||
                                currentRoute === Routes.FORGOT_PASSWORD
                                ? cn('desc_white')
                                : cn('desc')
                        }
                    >
                        для Москвы и заграницы
                    </div>
                </div>
            </div>
        </Popup>
    );

    return (
        <div className={cn()}>
            <div className={cn('header')}>
                <AttributedIconCall className={cn('icon')} onClick={handleIconClick}>
                    <IconCall />
                </AttributedIconCall>
                {renderModal()}
            </div>
            {currentRoute === Routes.FORGOT_PASSWORD ? null : (
                <div className={cn('vertical-footer')}>
                    <div className={cn('footer-line')}>
                        <div className={cn('item', { mobile: 'hide' })}>
                            <div className={cn('num')}>
                                {/* <Link size={ FORM_LINK_SIZE_M } url={ AlboDigestLink }> */}
                                {/*    Альфа-Бизнес Дайджест */}
                                {/* </Link> */}
                                {isCorporateClientId ? (
                                    <Link
                                        size={FORM_LINK_SIZE_M}
                                        url={HelpLink}
                                        target='_blank'
                                        onClick={handleQuestionClick}
                                    >
                                        Помощь
                                    </Link>
                                ) : null}
                            </div>
                        </div>
                    </div>
                    <div className={cn('footer-line', { mobile: 'hide' })}>
                        <div className={cn('item')}>
                            <div className={cn('num')}>
                                <Link
                                    size={FORM_LINK_SIZE_M}
                                    url={`tel:${getRussiaPhone()}`}
                                    onClick={handleRussianPhoneClick}
                                >
                                    {getRussiaPhone()}
                                </Link>
                            </div>
                            <div
                                className={
                                    currentRoute === Routes.FORGOT_LOGIN
                                        ? cn('desc_white')
                                        : cn('desc')
                                }
                            >
                                для звонков по России
                            </div>
                        </div>
                        <div className={cn('item')}>
                            <div className={cn('num')}>
                                <Link
                                    size={FORM_LINK_SIZE_M}
                                    url={`tel:${getMoscowPhone()}`}
                                    onClick={handleMoscowPhoneClick}
                                >
                                    {getMoscowPhone()}
                                </Link>
                            </div>
                            <div
                                className={
                                    currentRoute === Routes.FORGOT_LOGIN
                                        ? cn('desc_white')
                                        : cn('desc')
                                }
                            >
                                для Москвы и заграницы
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Phones;
