import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { createCn } from 'bem-react-classname';

import { Button } from '@alfalab/core-components/button';

import { useAppDispatch, useAppSelector } from '#/src/hooks';
import { trackUserEvent } from '#/src/lib/analitycs';
import { ButtonNames, Routes } from '#/src/models';
import { useInitializePasscodeMutation } from '#/src/store/api/passcode-api';
import { useInitializeTempPasswordMutation } from '#/src/store/api/temp-password-api';
import { getClientId } from '#/src/store/redux/app/selectors';
import {
    selectHideButton,
    selectIsPasscodeAuth,
    selectPasscodeTypes,
    selectSkeletonVisible,
} from '#/src/store/redux/passcode';
import { isAltAuthModalOpenSet } from '#/src/store/redux/passcode/slice';
import { selectIsTempPasswordAuth } from '#/src/store/redux/temp-password';

import BsAltLogin from './components/bs-alt-login';

import './alt-login-mobile.css';

export const cn = createCn('alt-login-mobile');

const AlternativeLoginMobile = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { search } = useLocation();
    const skeletonVisible = useAppSelector(selectSkeletonVisible);
    const hideButton = useAppSelector(selectHideButton);
    const isPasscodeAuth = useAppSelector(selectIsPasscodeAuth);
    const isTempPasswordAuth = useAppSelector(selectIsTempPasswordAuth);
    const clientId = useAppSelector(getClientId);
    const passcodeTypes = useAppSelector(selectPasscodeTypes);

    const [initializeTempPassword] = useInitializeTempPasswordMutation();
    const [initializePasscode] = useInitializePasscodeMutation();

    const [loading, setLoading] = useState(false);
    const [init, setInit] = useState(true);

    const handleAlternativeLogin = () => {
        /** Проверка на количество доступных способов авторизации */
        const activeAuthCount =
            [isPasscodeAuth, isTempPasswordAuth].filter((type) => type).length >= 2;

        if (activeAuthCount) {
            dispatch(isAltAuthModalOpenSet(true));
        } else if (isTempPasswordAuth) {
            navigate({
                pathname: Routes.TEMP_PASSWORD,
                search,
            });
        } else if (isPasscodeAuth) {
            const pathname = passcodeTypes.length === 0 ? Routes.PASSCODE : Routes.NEW_PASSCODE;

            navigate({
                pathname,
                search,
            });
        }

        trackUserEvent('Auth Page', 'Click', 'Click on button Another way to enter', clientId);
    };

    useEffect(() => {
        (async () => {
            await initializeTempPassword();
            await initializePasscode();
            setInit(false);
            setLoading(false);
        })();
    }, [initializePasscode, initializeTempPassword]);

    useEffect(() => {
        if (!loading && !init) {
            handleAlternativeLogin();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    if (skeletonVisible || hideButton) {
        return null;
    }

    return (
        <React.Fragment>
            <Button
                dataTestId='alternative-button'
                className={cn('button-wrapper')}
                block={true}
                type='button'
                onClick={() => {
                    if (init) {
                        setLoading(true);
                    } else {
                        handleAlternativeLogin();
                    }
                }}
                loading={loading}
            >
                {ButtonNames.alternativeLogin}
            </Button>

            <BsAltLogin />
        </React.Fragment>
    );
};

export default AlternativeLoginMobile;
