export const TextInform = {
    login: 'Как ещё можно войти',
    loginCard: 'Вход по\u00A0номеру карты',
    loginCardAccount: 'Вход по\u00A0номеру карты или\u00A0счёта',
    otherLogin: 'Другие способы входа',
    noOneLogin: 'Если ни\u00A0один способ не\u00A0подходит',
    cantLogin: 'Не получается войти?',
    howYouCanLogin: 'Как ещё можно войти',
};

export const TextInformList = [
    {
        id: 'tempPassword',
        text: 'По\u00A0временному паролю',
        caption:
            'Чтобы\u00A0получить, позвоните в\u00A0поддержку, обратитесь в\u00A0офис банка или\u00A0спросите у\u00A0сотрудника на\u00A0встрече',
    },
    {
        id: 'passcode',
        text: 'По\u00A0секретному коду',
        caption:
            'Это код от\u00A0приложения или\u00A0Альфа-Онлайн, с\u00A0которым вы\u00A0входили последний раз на\u00A0этом или\u00A0другом устройстве',
    },
];
